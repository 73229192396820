import React from 'react'
import Layout from "../components/layout"
import Back from "../components/back"
import Preview from '../components/Preview'

export default function () {
    return (
        <Layout>
                
<Preview/>

            <div 
            data-sal="slide-down"
            data-sal-duration="900"
            data-sal-delay="200"
            data-sal-easing="ease" class="flex flex-col justify-items-center md:w-5/12 mx-auto py-16 px-4 bg-secondary bg-opacity-30 mt-20">

                <p class="subpixel-antialiased text-3xl md:text-4xl font-semibold text-center text-orange-600 leading-normal md:leading-tight">Oops! That page wasn't found.</p>
                <div class="mx-auto mt-8">


                    <Back where="Go back home" />


                </div>
            </div>


        </Layout>
    )
}